export default defineAppConfig({
  netzo: {
    favicon: '/favicon.svg',
    title: 'ESGRAF',
    description: 'Software de gestión de empresas (ERP) de ESGRAF.',
    auth: {
      email: {
        allowUserRegistration: false,
      },
    },
  },
  ui: {
    primary: 'blue',
    gray: 'neutral',
  },
})
